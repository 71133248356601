import styled from "styled-components";
import Achtergrond from "../../images/Hero.jpg";

export const Main = styled.div`
  background-image: linear-gradient(
      to bottom right,
      rgba(255, 255, 255, 0),
      rgba(79, 119, 45, 0.7)
    ),
    url(${Achtergrond});

  background-position: center;
  background-size: cover;
  height: calc(100vh - 80px);
`;

export const TextWrapper = styled.div`
  text-align: center;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -70%);
`;

export const H1 = styled.h1`
  margin-top: 50px;
  font-weight: bold;
  color: #fff;
`;

export const H2 = styled.h2`
  padding-top: 50px;
  color: #fff;
`;
