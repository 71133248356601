import React from "react";

import { Main, ContentRow, ContentWrapper, H1, A, P } from "./footerElements";

function Footer() {
  const datum = Date.now();

  return (
    <Main>
      <ContentRow>
        <ContentWrapper>
          <H1>ONZE PARTNERS</H1>
          <P>
            <A
              rel="noopener"
              target="_blank"
              href="https://www.slagerijdros.nl/"
            >
              SLAGERIJ DROS
            </A>
          </P>

          <P>
            <A
              rel="noopener"
              target="_blank"
              href="https://www.natuurmonumenten.nl/"
            >
              NATUURMONUMENTEN
            </A>
          </P>

          <P>
            <A rel="noopener" target="_blank" href="https://www.delieuw.nl/">
              ANLV DE LIEUW
            </A>
          </P>

          <P>
            <A
              rel="noopener"
              target="_blank"
              href="http://www.slachtbedrijftexel.nl/slachtbedrijf-texel/"
            >
              SLACHTBEDRIJF TEXEL
            </A>
          </P>
        </ContentWrapper>
        <ContentWrapper>
          <H1>CONTACT</H1>
          <P>ADRES: Skillepaadje 18 1792CN</P>
          <P>POSTADRES: Vliegwiel 8 1792CS</P>
          <P>
            EMAIL:{" "}
            <A rel="noopener" href="mailto:jbvanderstar@texel.com">
              jbvanderstar@texel.com
            </A>
          </P>
          <P>
            TELEFOON:{" "}
            <A rel="noopener" href="tel:+31 6 20066709">
              +31 6 20066709
            </A>
          </P>
        </ContentWrapper>
      </ContentRow>
      <P>&copy; Copyright {new Date().getFullYear()} Brakestein</P>
    </Main>
  );
}

export default Footer;
