import React, { useState } from "react";
import picture from "../../images/wandelpaden.jpg";
import {
  Main,
  H1,
  TextWrapper,
  ContentRow,
  ImageWrapper,
  TitleWrapper,
  IMG,
  P,
} from "./InfoElements";

function Wandelpaden() {
  return (
    <Main>
      <TitleWrapper>
        <H1>WANDELPADEN</H1>
      </TitleWrapper>
      <ContentRow>
        <TextWrapper>
          <P>
            Op de landerijen rond de boerderij zijn wandelpaden aangelegd. De
            oevers langs de wandelpaden zijn brede oevers, deze geven extra
            waterberging en verhogen de natuur en landschapswaarden. Deze oevers
            maken deel uit van het Masterplan Water voor Texel. Voor meer
            informatie over de wandelpaden klik{" "}
            <a
              href="https://www.delieuw.nl/wandelroutes/brakenstein.html"
              target="_blank"
            >
              hier
            </a>
            .
          </P>
        </TextWrapper>
        <ImageWrapper>
          <IMG src={picture} alt="Wandelpaden de hogeberg" />
        </ImageWrapper>
      </ContentRow>
    </Main>
  );
}

export default Wandelpaden;
