import "./App.css";
import Navbar from "./components/navbar/Navbar";
import React, { useState } from "react";
import NavSlide from "./components/NavSlide/navSlide";
import Hero from "./components/Hero/Hero";
import OverOns from "./components/Info/OverOns";
import Vee from "./components/Info/Vee";
import Vlees from "./components/Info/Vlees";
import Locatie from "./components/Info/Locatie";
import Wandelpaden from "./components/Info/Wandelpaden";
import Stolp from "./components/Info/Stolp";
import Footer from "./components/footer/footer";

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="App">
      <NavSlide isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Hero />
      <div id="OverOns">
        <OverOns />
      </div>
      <div id="OnsVee">
        <Vee />
      </div>
      {/* <div id="TexelsVlees">
        <Vlees />
      </div> */}
      <div id="Locatie">
        <Locatie />
      </div>
      <div id="Stolp">
        <Stolp />
      </div>
      <div id="Wandelpaden">
        <Wandelpaden />
      </div>

      <Footer />
    </div>
  );
}

export default App;
