import React, { useState } from "react";
import picture from "../../images/stolp.jpg";
import {
  Main,
  H1,
  H2,
  ContentRow,
  TextWrapper,
  ImageWrapper,
  TitleWrapper,
  IMG,
  P,
} from "./InfoElements";

function Stolp() {
  return (
    <Main>
      <TitleWrapper>
        <H1>De Stolp</H1>
      </TitleWrapper>
      <ContentRow>
      <ImageWrapper>
          <IMG src={picture} alt="De stolp met apartementen" />
        </ImageWrapper>
        <TextWrapper>
          <P>
            De stolpboerderij Brakestein is gelegen
            op een prachtige plek met veel historie aan het Skillepaadje aan de
            voet van natuurgebied De Hoge Berg. Tegenover de boerderij liggen de
            Wezenputten. Deze waren eigendom van het weeshuis in Den Burg, dat
            het water verkocht aan de schepen van de VOC die vanaf de Reede van
            Texel vertrokken. In de historische stolpboerderij zijn in 2021 drie geheel nieuwe
            appartementen gerealiseerd en zijn nu voor verhuur beschikbaar.
            <br /><br />
            Klik <a href="https://www.texelduinen.nl/de/zoeken-en-boeken/vakantiehuizen-op-texel/195-stolp-brakestein-appartement-a" target="_blank"> hier</a> voor meer informatie en beschikbaarheid.
            <br />{" "}
          </P>
        </TextWrapper>
        
      </ContentRow>
    </Main>
  );
}

export default Stolp;
