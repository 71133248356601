import React, { useState } from "react";
import picture from "../../images/OverOns.jpg";
import {
  Main,
  H1,
  H2,
  ContentRow,
  TextWrapper,
  ImageWrapper,
  TitleWrapper,
  IMG,
  P,
} from "./InfoElements";

function OverOns() {
  return (
    <Main>
      <TitleWrapper>
        <H1>OVER ONS</H1>
      </TitleWrapper>
      <ContentRow>
        <TextWrapper>
          <P>
            Het veebedrijf is opgericht door Piet en Zina van der Star op 1
            april 1991. Piet en Zina beide afkomstig uit een boeren familie
            hebben hoeve Brakestein in 1991 van Eef Breem overgenomen. <br />
            <br />
            Eef hield zijn vee in de stolp (anno 1899). Na een paar jaar in de
            stolp geboerd te hebben, is er om de werkdruk te verlagen en de
            dieren meer bewegingsruimte te geven is een potstal gebouwd. Het
            bedrijf is nu uitgebreid naar 135 koeien en drie stallen.
            Ondertussen zijn de tweede en derde generatie van de familie Jan en
            Ben ook actief in het bedrijf.{" "}
          </P>
        </TextWrapper>
        <ImageWrapper>
          <IMG src={picture} alt="Koeien en boer" />
        </ImageWrapper>
      </ContentRow>
    </Main>
  );
}

export default OverOns;
