import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarRoute,
  SideBtnWrap,
} from "./NavSlideElements";

const NavSlide = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink
            to="OverOns"
            onClick={toggle}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            OVER ONS
          </SidebarLink>
          <SidebarLink
            to="OnsVee"
            onClick={toggle}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            ONS VEE
          </SidebarLink>
          {/* <SidebarLink
            to="TexelsVlees"
            onClick={toggle}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            TEXELS VLEES
          </SidebarLink> */}
          <SidebarLink
            to="Locatie"
            onClick={toggle}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            LOCATIE
          </SidebarLink>
          <SidebarLink
            to="Stolp"
            onClick={toggle}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            DE STOLP
          </SidebarLink>
          <SidebarLink
            to="Wandelpaden"
            onClick={toggle}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            WANDELPADEN
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default NavSlide;
