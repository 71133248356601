import React, { useState } from "react";
import picture from "../../images/vee.jpg";
import {
  Main,
  H1,
  TextWrapper,
  ContentRow,
  ImageWrapper,
  TitleWrapper,
  IMG,
  P,
} from "./InfoElements";

function OverOns() {
  return (
    <Main>
      <TitleWrapper>
        <H1>ONS VEE</H1>
      </TitleWrapper>
      <ContentRow>
        <ImageWrapper>
          <IMG alt="grazend vee" src={picture} />
        </ImageWrapper>
        <TextWrapper>
          <P>
            In 1991 zijn wij Blonde d'Aquitaine gaan houden. De voornaamste
            reden hiervoor was om de afkalfproplemen te verlagen. Voor de
            overstap hielden we diverse soorten en rassen. Wij zijn trots op de
            Blonde d’Aquitaine-runderen. <br /> <br />
            Het zijn mooie, grote en elegante dieren, met een goede bespiering.
            De fijne huid en horens zijn kenmerken van een zeer goede
            vleeskwaliteit, die perfect aansluit bij de vraag van
            kwaliteitsslagerijen op ons eiland. <br /> <br />
            De groeisnelheid en de hoge eindgewichten van de dieren, in
            combinatie met de makkelijke geboortes, zorgen ervoor dat het Blonde
            d’Aquitaine rund het best renderende vleesras in Nederland is.
            Blondes zijn vruchtbare moederdieren, van rond de duizend kilo, die
            probleemloos 12 tot 15 jaar oud kunnen worden. Zij geven langgerekte
            kalveren, die op natuurlijke wijze geboren worden.
          </P>
        </TextWrapper>
      </ContentRow>
    </Main>
  );
}

export default OverOns;
