import React, { useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import {
  Main,
  H1,
  H2,
  ContentRow,
  TextWrapper,
  ImageWrapper,
  TitleWrapper,
  IMG,
  P,
  IFRAME,
} from "./InfoElements";

function Locatie() {
  return (
    <Main>
      <TitleWrapper>
        <H1>LOCATIE</H1>
      </TitleWrapper>

      <ContentRow>

        <TextWrapper>
          <P>
            Ons bedrijf ligt aan het skillepaadje op de hogeberg, midden in een
            weidevogel gebied. Om de vogels de tijd te geven om hun kuikens
            groot te laten worden, maaien wij op een latere datum als
            gebruikelijk. Wij maken gebruik van een potstal structuur, dit
            houdt in dat de mest in de stal opgepot wordt, wanneer de stal vol
            zit wordt hij uitgemest. Wij strooien de mest uit over onze
            landerijen. Dit bevordert het grondleven waar de vogels van leven.
          </P>
        </TextWrapper>
        <ImageWrapper>
          <div class="mapouter">
            <div class="gmap_canvas">
              <IFRAME
                title="Locatie Brakestein"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=53.040329298152976,%204.830881646209115&t=&z=15&ie=UTF8&iwloc=&output=embed"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></IFRAME>
              <a href="https://putlocker-is.org"></a>
              <br />
            </div>{" "}
          </div>
        </ImageWrapper>
      </ContentRow>
    </Main>
  );
}

export default Locatie;
