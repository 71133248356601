import styled from "styled-components";

export const Main = styled.div`
  text-align: center;
  max-width: 1400px;
  
  padding-top 30px;
  margin-right: auto;
  margin-left: auto;
`;

export const TitleWrapper = styled.div`
  text-align: center;
`;

export const H1 = styled.h1`
  font-weight: bold;
  color: #4f772d;
`;

export const ContentRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const TextWrapper = styled.div`
  position: relative;
  align-items: center;
  text-align: left;
  margin: 20px;
`;

export const ImageWrapper = styled.div`
  position: relative;
  align-items: center;
  text-align: center;
  margin: 20px;
`;

export const H2 = styled.h2``;

export const IMG = styled.img`
  margin-right: auto;
  margin-left: auto;
  border-radius: 2px;
  width: 80%;
  object-fit: cover;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const P = styled.p`
  font-size: 1.3rem;
`;

export const IFRAME = styled.iframe`
  width: 100%;
  height: 25rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;
