import styled from "styled-components";

export const Main = styled.footer`
  background: #4f772d;
  color: #fff;
  text-align: center;
  padding: 10px;
`;

export const TextWrapper = styled.div``;

export const H1 = styled.h1`
  font-size: 30px;
  color: #fff;
`;

export const H2 = styled.h2``;

export const ContentRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const ContentWrapper = styled.div`
  text-align: center;
`;

export const A = styled.a`
  color: #fff;
  text-decoration: none;

  &:hover {
    color: #fff;
    font-weight: bold;
  }
`;

export const P = styled.p`
  padding: 10px;
  margin: 0px;
`;
