import React, { useState } from "react";

import { Main, H1, H2, TextWrapper } from "./HeroElements";

function Hero() {
  return (
    <Main>
      <TextWrapper>
        <H1>Welkom bij Boerderij Brakestein</H1>
        <H2>Lees verder en kom meer te weten over ons bedrijf!</H2>
      </TextWrapper>
    </Main>
  );
}

export default Hero;
